<template>
  <div class="body">
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="450px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
  import api from '../../util/extra-api'
  import Echart from '../../common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          title: {
            text: "",
          },
          dataZoom: [
            {
              type: "inside",
              show: true,
              start: 0,
              end: 100,
              xAxisIndex: [0],
            }
          ],
          tooltip: {
            trigger: "axis",
            backgroundColor: "#7bbfea",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC"
              }
            },
            formatter: function (params) {
               //console.log(params); // 当我们想要自定义提示框内容时，可以先将鼠标悬浮的数据打印出来，然后根据需求提取出来即可
              let firstParams = params[0];
              return firstParams.name  + '<br>' + '金额：' + firstParams.data + ' (元)<br>次数：' + newData.countList[firstParams.dataIndex] +' (次)';
            }
          },
          // legend: {
          //   data: ["品类收入排行", "品类销售排行"],
          //   textStyle: {
          //     color: "#B4B4B4"
          //   },
          //   top: "0%"
          // },
          // grid: {
          //   x: "8%",
          //   width: "88%",
          //   y: "4%"
          // },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              interval:0,
              // rotate: -10,
              formatter:function(value)
              {
                // debugger
                var ret = "";//拼接加\n返回的类目项
                var maxLength = 2;//每项显示文字个数
                var valLength = value.length;//X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于3,
                {
                  for (var i = 0; i < 5; i++) {
                    var temp = "";//每次截取的字符串
                    var start = i * maxLength;//开始截取的位置
                    var end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                }
                else {
                  return value;
                }
              }
            }
          },
          grid: {
            left: '20',
            right: '0',
            bottom: '0',
            top: '25',
            containLabel: true
          },
          yAxis: [
            {
              type: 'value',
              name: newData.type === 0 ? '(元)' : '(元)',
              nameTextStyle:{
                color: '#fff',
                fontSize: 14
              },
              nameGap: 10,
              splitLine: { show: false },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#B4B4B4"
                }
              },

              axisLabel: {
                formatter: "{value} "
              }
            },
            // {
            //   splitLine: { show: false },
            //   axisLine: {
            //     lineStyle: {
            //       color: "#B4B4B4"
            //     }
            //   },
            //   axisLabel: {
            //     formatter: "{value} "
            //   }
            // }
          ],
          series: [
            {
              name: newData.type === 0 ? "金额" : "金额",
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#72baa7" },
                    { offset: 1, color: newData.type === 0 ?  "#3EACE5" : "#f26522" }
                  ]),
                  label: {
                    show: true,		//开启显示
                    position: 'top',	//在上方显示
                    textStyle: {	    //数值样式
                      color: 'white',
                      fontSize: 14
                    }
                  }
                }
              },
              data: newData.barData
            },
            {
              name: "件数",
              type: "bar",
              barGap: "-100%",
              barWidth: 10,
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(156,107,211,0.8)" },
                    { offset: 0.2, color: "rgba(156,107,211,0.5)" },
                    { offset: 1, color: "rgba(156,107,211,0.2)" }
                  ])
                }
              },
              z: -12,
              data: newData.lineData
            }
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>
<style>
  .body{
    height: 550px;
  }

</style>
