<template>
  <div class="body">
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="450px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
  import api from '../../util/extra-api'
  import Echart from '../../common/echart'
export default {
  data () {
    return {
      options: {},
      tipsTitle:['货值','重量','','成本']
    };
  },
  components: {
    Echart
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
    status:{
      type: Number,
    }
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          dataZoom: [
            {
              type: "inside",
              show: true,
              start: 0,
              end: newData.end,
              xAxisIndex: [0],
            }
          ],

          title: {
            text: "",
          },
          tooltip: {
            trigger: "axis",
            backgroundColor: "#7bbfea",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC"
              }
            }
          },
          // legend: {
          //   data: ["品类收入排行", "品类销售排行"],
          //   textStyle: {
          //     color: "#B4B4B4"
          //   },
          //   top: "0%"
          // },
          grid: {
            left: '15',
            right: '0',
            bottom: '10',
            top: '25',
            containLabel: true
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              interval:0,
              // rotate: -10,
              formatter:function(value)
              {
                // debugger
                var ret = "";//拼接加\n返回的类目项
                var maxLength = 2;//每项显示文字个数
                var valLength = value.length;//X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于3,
                {
                  for (var i = 0; i < 5; i++) {
                    var temp = "";//每次截取的字符串
                    var start = i * maxLength;//开始截取的位置
                    var end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                }
                else {
                  return value;
                }
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: this.status === 1 ? '(kg)' : '(元)',
              nameGap: 10,
              nameTextStyle:{
                color: '#fff',
                fontSize: 14
              },

              splitLine: { show: false },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#B4B4B4"
                }
              },

              axisLabel: {
                formatter: "{value} "
              }
            },
            // {
            //   splitLine: { show: false },
            //   axisLine: {
            //     lineStyle: {
            //       color: "#B4B4B4"
            //     }
            //   },
            //   axisLabel: {
            //     formatter: "{value} "
            //   }
            // }
          ],
          series: [
            {
              // name: newData.status === 0 ? "货值" : "重量",
              name: this.tipsTitle[newData.status],
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  // barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: this.cdata.color[0] },
                    { offset: 1, color: this.cdata.color[1] }
                  ]),
                  label: {
                    show: true,		//开启显示
                    position: 'top',	//在上方显示
                    textStyle: {	    //数值样式
                      color: 'white',
                      fontSize: 14
                    }
                  }
                }
              },
              data: newData.barData
            },
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>
<style>
  .body{
    height: 550px;
  }

</style>
