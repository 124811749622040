<template>
  <div class="body">
    <div class="c-head">
      <div class="df">
        <div @click="changeType(2)" class="box-btn bgc-956"></div>
        <div>全部</div>
      </div>
      <div class="df">
        <div @click="changeType(0)" class="box-btn bgc-45b"></div>
        <div>重点物资</div>
      </div>
      <div class="df">
        <div @click="changeType(1)" class="box-btn bgc-3EA"></div>
        <div>制售品</div>
      </div>
    </div>
    <div class="c-head-left">
      <div class="df">
        <div @click="changeStatus(1)" class="box-btn bgc-33a"></div>
        <div>重量</div>
      </div>
      <div class="df">
        <div @click="changeStatus(0)" class="box-btn bgc-ffd"></div>
        <div class="df" style="position: relative">
          货值
          <div
            style="position: absolute; top: 24px; left: -40px;width: 100px"
            v-if="cdata.status == 0"
          >
            <i>{{ totalMoney }}元</i>
          </div>
        </div>
      </div>
      <div class="df">
        <div @click="changeStatus(3)" class="box-btn bgc-4da"></div>
        <div class="df" style="position: relative">
          成本
          <div
            style="position: absolute; top: 24px; left: -40px;width: 100px"
            v-if="cdata.status == 3"
          >
            <i>{{ totalMoney }}元</i>
          </div>
        </div>
      </div>
    </div>
    <Chart :cdata="cdata" :status="cdata.status" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
import api from "../../util/extra-api";
export default {
  data() {
    return {
      data: null,
      cdata: {
        category: [],
        lineData: [],
        barData: [],
        rateData: [],
        type: 0,
        color: ["#3EACE5", "#00ae9d"],
        status: 1,
        end: 100,
      },
      type: 1,
      totalMoney: 0,
    };
  },
  components: {
    Chart,
  },
  mounted() {
    this.getChart8();
  },
  methods: {
    changeType(type) {
      this.type = type;
      this.$emit("changeType", type);
      if (type === 2) {
        this.cdata.color = ["#3EACE5", "#00ae9d"];
      } else if (type === 0) {
        this.cdata.color = ["#00ae9d", "#fdb933"];
      } else {
        this.cdata.color = ["#fdb933", "#3EACE5"];
      }
      this.getChart8();
    },
    changeStatus(status) {
      this.cdata.status = status;
      this.getChart8();
    },
    getChart8() {
      api
        .get("/v1/wx/companyScreen/chart8", {
          companyId: localStorage.getItem("company_id"),
          type: this.type,
          status: this.cdata.status,
        })
        .then((res) => {
          if (res.code === 200) {
            this.data = res.data;
            this.cdata.category = this.data.map((m) => m.goodsName);
            if (this.cdata.status === 0 || this.cdata.status === 3) {
              this.cdata.barData = this.data.map((m) => m.money);

              this.totalMoney = this.cdata.barData
                .reduce((acc, cur) => acc + cur, 0)
                .toFixed(2);
              parseFloat(this.totalMoney).toFixed(2);
              //console.log(this.cdata.barData.reduce((a,b)=>a+b))
            } else {
              this.cdata.barData = this.data.map((m) => m.stock);
            }
            if (this.cdata.barData.length >= 10) {
              this.cdata.end = (10 / this.cdata.barData.length) * 100;
            } else {
              this.cdata.end = 100;
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.c-head {
  position: absolute;
  top: 50px;
  right: 0;
  display: flex;
}
.c-head-left {
  position: absolute;
  top: 50px;
  left: 14px;
  display: flex;
}
.df {
  display: flex;
  justify-content: end;
  padding-right: 6px;
}

.box-btn {
  height: 20px;
  width: 40px;
  border-radius: 25px;
  line-height: 20px;
  cursor: pointer;
}
.bgc-956 {
  background: linear-gradient(to right, #3eace5, #00ae9d);
}
.bgc-45b {
  background: linear-gradient(to right, #00ae9d, #fdb933);
}
.bgc-3EA {
  background: linear-gradient(to right, #fdb933, #3eace5);
}
.bgc-ffd {
  background: #ffd400;
}
.bgc-33a {
  background-color: #33a3dc;
}
.bgc-4da {
  background-color: #4da409;
}
.body {
  height: 550px;
}
.df1 {
  display: flex;
}
.fdc {
  flex-direction: column;
  justify-content: flex-start;
}
</style>
