import { render, staticRenderFns } from "./CompanyScreen.vue?vue&type=template&id=9215b474&scoped=true&"
import script from "./CompanyScreen.vue?vue&type=script&lang=js&"
export * from "./CompanyScreen.vue?vue&type=script&lang=js&"
import style0 from "./CompanyScreen.vue?vue&type=style&index=0&id=9215b474&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9215b474",
  null
  
)

export default component.exports