<template>
  <div class="body">
    <div style="position: absolute; top: 0">
      <el-popover
        placement="bottom"
        width="100%"
        trigger="click"
        v-model="showTypePopover"
        transition="el-zoom-in-top"
      >
        <div style="padding: 20px">
          <div
            style="
              justify-content: space-between;
              display: flex;
              font-size: 18px;
              padding: 20px;
            "
          >
            <div style="width: 100px"></div>
            <div>计入统算的报销项目</div>
            <div>
              <div class="btn-checkAll" @click="checkAll">
                {{ isCheckAll ? "取消全选" : "全选" }}
              </div>
            </div>
          </div>
          <div style="display: flex; flex-wrap: wrap; max-width: 500px">
            <div
              v-for="(item, index) in typeList2"
              :key="index"
              class="tag-item"
              :class="{ 'bgc-ebf': item.checked }"
              @click="checkboxClick(index, 0)"
            >
              {{ item.iconName }}
            </div>
          </div>
          <div class="mt10 tr">
            <button class="btn-reset" @click="typeConfirm(0)">重置</button>
            <button class="btn-add" @click="typeConfirm(1)">确定</button>
          </div>
        </div>
        <!--          <el-button slot="reference">click 激活</el-button>-->
        <div
          slot="reference"
          style="position: absolute; top: 32px; left: 100px"
          class="box-i-time"
        >
          <i class="el-icon-s-tools i-time"></i>
        </div>
      </el-popover>
    </div>
    <div class="c-head">
      <div class="df" style="padding-right: 30px">
        <div @click="changeType(0)" class="box-btn bgc-3EA"></div>
        <div>收入</div>
        <div
          style="
            width: 140px;
            line-height: 21px;
            padding-left: 20px;
            display: flex;
          "
        >
          {{ totalIncomesMoney }}元
        </div>
      </div>
      <div class="df pt5" style="padding-right: 30px">
        <div @click="changeType(1)" class="box-btn bgc-f26"></div>
        <div>支出</div>
        <div
          style="
            width: 140px;
            line-height: 21px;
            padding-left: 20px;
            display: flex;
          "
        >
          {{ totalExpenditureMoney }}元
        </div>
      </div>
    </div>
    <dv-loading class="loading" v-show="loading"></dv-loading>
    <Chart v-if="show" :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
import api from "../../util/extra-api";
export default {
  data() {
    return {
      loading: true,
      data: null,
      cdata: {
        category: [],
        lineData: [],
        barData: [],
        rateData: [],
        type: 1,
        countList: [],
      },
      startTime: "",
      endTime: "",
      show: false,
      showTypePopover: false,
      typeList1: [],
      typeList2: [],
      isCheckAll: false,
      totalExpenditureMoney: null,
      totalIncomesMoney: null,
    };
  },
  components: {
    Chart,
  },
  mounted() {
    //console.log("time2",localStorage.getItem('time2'))
    if (localStorage.getItem("time2")) {
      let time2 = JSON.parse(localStorage.getItem("time2"));
      this.startTime = time2.startTime;
      this.endTime = time2.endTime;
    }
    this.getChart3();
    api
      .get("/v1/wx/companyScreen/tallyIcon", {
        companyId: localStorage.getItem("company_id"),
      })
      .then((res) => {
        if (res.code === 200) {
          this.typeList1 = res.data;
          this.typeList2 = res.data.filter((f) => f.type === this.cdata.type);
        }
      });
  },
  methods: {
    checkAll() {
      this.typeList2.forEach((m) => (m.checked = !this.isCheckAll));
      this.isCheckAll = !this.isCheckAll;
      this.$forceUpdate();
    },
    checkboxClick(index) {
      this.typeList2[index].checked = !this.typeList2[index].checked;
      this.isCheckAll = this.typeList2.every((e) => e.checked);
      this.$forceUpdate();
    },
    typeConfirm(type) {
      if (type === 1) {
        this.getChart3();
      } else {
        this.typeList2.forEach((m) => (m.checked = false));
        this.isCheckAll = false;
        this.getChart3();
      }
      // this.$refs.blChart.parentHandleclick({startTime: this.startTime,endTime: this.endTime});
      this.showTypePopover = false;
    },
    parentHandleclick(e) {
      //console.log(e)
      this.startTime = e.startTime;
      this.endTime = e.endTime;
      let time2 = {
        startTime: this.startTime,
        endTime: this.endTime,
      };
      localStorage.setItem("time2", JSON.stringify(time2));
      this.getChart3();
    },
    changeType(type) {
      this.typeList2 = this.typeList1.filter((f) => f.type === type);
      this.getChart3();
      this.$emit("changeType", type);
      //console.log("this.data",this.data)
      if (type === 0) {
        this.cdata.category = this.data.incomes.map((m) => m.name);
        this.cdata.barData = this.data.incomes.map((m) => m.money);
        this.cdata.countList = this.data.incomes.map((m) => m.count);
        this.cdata.type = type;
      } else {
        this.cdata.category = this.data.expenditure.map((m) => m.name);
        this.cdata.barData = this.data.expenditure.map((m) => m.money);
        this.cdata.countList = this.data.expenditure.map((m) => m.count);
        this.cdata.type = type;
      }
    },
    getChart3() {
      this.loading = true;
      this.show = false;
      let names = this.typeList2
        .filter((m) => m.checked && m.iconName !== "自定义")
        .map((m) => m.iconName);
      // console.log('names',names)
      let data = {
        companyId: localStorage.getItem("company_id"),
        startTime: this.startTime,
        endTime: this.endTime,
        names: names.length > 0 ? names.join(",") : null,
        icons: this.typeList2.some((m) => m.icon === "zidingyi" && m.checked)
          ? "zidingyi"
          : null,
      };
      api.get("/v1/wx/companyScreen/chartTwo6", data).then((res) => {
        //console.log("chartTwo6",res)
        if (res.code === 200) {
          this.data = res.data;
          this.totalExpenditureMoney = res.data.totalExpenditureMoney;
          this.totalIncomesMoney = res.data.totalIncomesMoney;
          if (this.cdata.type === 0) {
            this.cdata.category = this.data.incomes.map((m) => m.name);
            this.cdata.barData = this.data.incomes.map((m) => m.money);
            this.cdata.countList = this.data.incomes.map((m) => m.count);
          } else {
            this.cdata.category = this.data.expenditure.map((m) => m.name);
            this.cdata.barData = this.data.expenditure.map((m) => m.money);
            this.cdata.countList = this.data.expenditure.map((m) => m.count);
          }
          // this.cdata.category = this.data.expenditure.map(m => m.name)
          // this.cdata.barData = this.data.expenditure.map(m => m.money)
          // this.cdata.countList = this.data.expenditure.map(m => m.count)
          this.loading = false;
          this.show = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.c-head {
  position: absolute;
  top: 30px;
  right: 0;
}
.df {
  display: flex;
  justify-content: end;
  padding-right: 100px;
}

.box-btn {
  height: 20px;
  width: 40px;
  border-radius: 25px;
  line-height: 20px;
  cursor: pointer;
  margin-right: 5px;
}
.bgc-956 {
  background-color: #72baa7;
}
.bgc-f26 {
  background-color: #f26522;
}
.bgc-3EA {
  background-color: #3eace5;
}
.body {
  height: 550px;
}
.tag-item {
  margin-right: 20px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #f9fafc;
  color: #464547;
  font-size: 18px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
.bgc-ebf {
  background-color: #7bbfea !important;
  color: #1b315e !important;
}
</style>
